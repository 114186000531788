@import '@core/scss/core.scss';
@import './assets/scss/styles';


.united {
    .tab-pane {
        height: 100% !important;
    }


}

.btn-warning {
    color: white !important;
    border-radius: 8px !important;
    background: #FF9501 !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #FFFFFF !important;
}

.spark-line-custome {

    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    /* identical to box height, or 145% */


    /* White/White 20 */

    color: rgba(255, 255, 255, 0.2);


}

.apexcharts-yaxis-texts-g {
    transform: none !important;
}

.apexcharts-gridline {
    transform: translate(100px, -3px) !important;
}

.apexcharts-grid {
    transform: rotateY(40deg);
}

html,
body {
    height: 100%;
    overflow-x: hidden;
}z

body {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-thumb {
    background-color: #474747;
    border-radius: 8px;
}

.canvas-style {

    width: 683px !important;
    height: 683px !important;
}

.inner-text-item {
    background: rgba(255, 255, 255, 0.1);
    
    backdrop-filter: blur(20px);
    z-index: 1;
    color: #E6E6E6 !important;
    position: relative;
    display: flex !important;
    align-items: center;

    .triangle-inner-text {
        display: flex;
        position: absolute;
        left: -5px;
        top: 47%;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 5px solid #474747;
        border-bottom: 5px solid transparent;
    }
}

.tooltip-sub {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
}

.map__circles {
    height: 100%;
}

.tooltip-data {
    padding-left: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
}

.tooltip-description {
    text-align: start;
    width: 250px;
}

.tooltip-overtaking {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 3px;
    gap: 8px;
    margin: 2px;
    color: #F8956C;
    border-radius: 4px;
    text-align: start;
}

.tooltip-new {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 3px;
    margin: 0 4px;
    gap: 8px;
    // margin: 2px;
    background: #860EFF;
    border-radius: 4px;
}

.tooltip-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

@media (max-width: 1030px) {
    .canvas-style {

        width: 512px !important;
        height: 512px !important;
    }
}

@media (max-width: 540px) {
    .canvas-style {

        width: 400px !important;
        height: 400px !important;
    }
}

@media (max-width: 4000px) {
    .canvas-style {

        width: 350px !important;
        height: 350px !important;
    }
}
.loader-container{
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    .loader {
        position: absolute;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        width: 48px;
        height: 48px;
        border: 5px solid #FFF;
        border-bottom-color: #333333;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        }
}


@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


    .modal-content{
        border-color: none !important;
        background-color: #202020  !important;
     
        border-radius: 8px;
    }
   
    .btn-warning {
        margin-bottom: 8px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 40px;
        margin: 0 !important;
    }

    .btn-secondary {
        border: 0;
        background: rgba(255, 255, 255, 0.1) !important;
        color: white !important;
        border-radius: 8px !important;
    }